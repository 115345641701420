import React from 'react';
import './office-hours.module.scss';
import { useGeneralSettings } from 'hooks/useGeneralSettings';

interface OfficeHoursProps {
  slug: string
}

export const OfficeHours = ({
  slug,
}: OfficeHoursProps) => {
  const { formattedOffices } = useGeneralSettings();
  const prefix: string = 'fr-office-hours';

  const mainOffice = formattedOffices[0],
        email = mainOffice?.email,
        simpleOfficeHours = mainOffice?.simpleOfficeHours.replaceAll(' | ', '\n'),
        simpleServiceHours = mainOffice?.simpleServiceHours.replaceAll(' | ', '\n');

  if (
    (simpleOfficeHours || simpleServiceHours) &&
    slug === "contact-us"
  )
    return (
      <div className={ `fr-container ${prefix}` }>
          { simpleOfficeHours && (
            <span className={ `${prefix}__office` }>
              <strong>Office Hours:</strong> { simpleOfficeHours }
            </span>
          ) }
          { simpleServiceHours && (
            <span className={ `${prefix}__service` }>
              <strong>Service Hours:</strong> { simpleServiceHours }
            </span>
          ) }
          { email && (
            <span className={ `${prefix}__email` }>
              <strong>Email:</strong> { email }
            </span>
          ) }
		  </div>
    )
  else return <></>
}

import React from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { Breadcrumbs } from "components/breadcrumbs/Breadcrumbs"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { GenericHero } from "components/hero/GenericHero/GenericHero"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { LocalBusinessSchema } from "components/schema/LocalBusinessSchema"
import { OfficeHours } from "components/office-hours/OfficeHours"
import { SimpleTileList } from "components/simple-tile/SimpleTileList"
import { StubBanner } from "components/form-stub/StubBanner"
import { TabbedHeroBanner } from "components/hero/TabbedHeroBanner"
import { BlocksWrapper } from "components/blocks-wrapper/BlocksWrapper"

interface GenericInteriorProps extends PageProps {
  pageContext: {
    slug: string
    breadcrumb: {
      crumbs: Record<string, string>[]
      location: string
    }
    linkedPagesUrlPathCodex: Record<string, string>
  }
  data: any
}

const GenericInterior: React.FC<GenericInteriorProps> = ({
  location,
  data,
  pageContext,
}) => {
  const { pathname, hash } = location
  const { slug, linkedPagesUrlPathCodex, breadcrumb } = pageContext

  const { globalFormStubBanner } = useGeneralSettings()

  const [page] = data.allKontentItemPage.nodes
  const {
    banner,
    rich_content,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    seo_metadata__canonical_link,
    seo_metadata__rich_data_image,
    seo_metadata__rich_data_link,
    is_pest_library_parent_page,
    is_gallery_parent_page,
    is_location_page,
    alternating_content,
    noindex_this_page,
    linked_item,
    global_footer,
    // legacy_navigation_grid_alt_background,
  } = page.elements

  // const legacyNavGridHasAltBackground =
  //   legacy_navigation_grid_alt_background?.value[0]?.codename! === "yes"
  //     ? true
  //     : false

  const isPestLibrary =
    is_pest_library_parent_page?.value?.length > 0 &&
    is_pest_library_parent_page?.value[0]?.codename === "yes"
      ? true
      : false
  const isGallery =
    is_gallery_parent_page?.value?.length > 0 &&
    is_gallery_parent_page?.value[0]?.codename === "yes"
      ? true
      : false

  const isLocationPage =
    is_location_page?.value?.length > 0 &&
    is_location_page?.value[0]?.codename === "yes"
      ? true
      : false

  const hasAlternatingContentBlocks =
    alternating_content.value[0]?.codename === "no" ? false : true

  const noIndexThisPage =
    noindex_this_page.value[0]?.codename === "yes" ? true : false

  const noIndexPages = [
    "accessibility-statement",
    "terms-of-use",
    "terms-of-service",
    "privacy-policy",
  ]

  const noIndex =
    noIndexPages.some(page => page.includes(slug)) || noIndexThisPage

  return (
    <Layout
      location={slug}
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={seo_metadata__meta_title?.value}
        description={seo_metadata__meta_description?.value}
        canonical={seo_metadata__canonical_link?.value}
        noIndex={noIndex}
        image={seo_metadata__rich_data_image}
      />
      {isLocationPage && (
        <LocalBusinessSchema
          serviceArea={seo_metadata__meta_title.value}
          richDataImageUrl={seo_metadata__rich_data_image?.value[0]?.url! || ""}
          richDataLink={seo_metadata__rich_data_link?.value! || ""}
        />
      )}
      {banner.value[0].elements.hasOwnProperty("hero_tabs") ? (
        <TabbedHeroBanner banner={banner.value[0].elements} />
      ) : (
        <GenericHero banner={banner.value[0].elements} />
      )}

      {globalFormStubBanner && Object.keys(globalFormStubBanner).length > 0 && (
        <StubBanner formStub={globalFormStubBanner} />
      )}

      {isLocationPage && (
        <Breadcrumbs
          crumbs={breadcrumb.crumbs}
          crumbLabel={seo_metadata__meta_title.value}
        />
      )}

      <BlocksWrapper>
        <OfficeHours slug={slug} />

        {rich_content?.modular_content?.length > 0 && (
          <ContentBlocks
            content={rich_content.modular_content}
            hasAlternatingContentBlocks={hasAlternatingContentBlocks}
          />
        )}

        {(isPestLibrary || isGallery) && linked_item?.value?.length > 0 && (
          <SimpleTileList tiles={linked_item.value} />
        )}
      </BlocksWrapper>
      {global_footer && global_footer?.value.length > 0 && (
        <GlobalFooter content={global_footer} />
      )}
    </Layout>
  )
}

export const getPage = graphql`
  query ($slug: String!) {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          legacy_navigation_grid_alt_background {
            value {
              codename
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__rich_data_image {
            value {
              url
            }
          }
          seo_metadata__rich_data_link {
            value
          }
          is_gallery_parent_page {
            value {
              codename
            }
          }
          is_pest_library_parent_page {
            value {
              codename
            }
          }
          alternating_content {
            value {
              codename
            }
          }
          is_location_page {
            value {
              codename
            }
          }
          noindex_this_page {
            value {
              codename
            }
          }
          linked_item {
            value {
              ... on kontent_item_page {
                id
                elements {
                  slug {
                    value
                  }
                  slug_generator {
                    value
                  }
                  seo_metadata__rich_data_image {
                    value {
                      url
                      width
                      height
                      description
                    }
                  }
                  seo_metadata__overlay_cta_text {
                    value
                  }
                  seo_metadata__meta_title {
                    value
                  }
                }
              }
            }
          }
          banner {
            value {
              ...HeroBannerFragment
              ...TabbedHeroBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`
export default GenericInterior

import React from "react"

interface BlocksWrapperProps {
  children: React.ReactNode
}

export const BlocksWrapper = ({ children }: BlocksWrapperProps) => {
  return (
    <div className="first-last-block-spacing">
      <>{children}</>
    </div>
  )
}
